import React, { useEffect } from "react";

import { Button } from "components/Buttons";

import {
  Header,
  InfoTexts,
  Container,
  SeachField,
  PerfilInfos,
  DeleteWrapper,
  TableContainer,
  SearchContainer,
  HeaderButtonsWrapper,
} from "./style";

import { DataGrid } from "@mui/x-data-grid";
import { DeleteSVG, PlusSVG, SearchSVG } from "assets/svg/components";
import { search as getTravels } from "../../../api/requests/travel/search";
import { Travel, TravelReceive } from "../../../types/data/Travel";
import { useMutation, useQuery } from "react-query";
import { formatedDateToShow } from "utils/formatters";
import { useNavigate } from "react-router-dom";
import { NEW_ITEM, useSelectedIdContext } from "context/SelectedIdContext";
import ConfirmModal from "components/ConfirmModal";
import { remove } from "api/requests/travel/remove";
import { toast } from "react-toastify";
import { queryClient } from "api/queryClient";
import { handleSetStatus } from "utils/getTravelStatus";
import { Stack } from "@mui/material";
import { ButtonWrapper } from "./style";
import { ILimit } from "../Register/types";
import { PageSizeSelector } from "pages/Clients/List/Table/styles";
import { columns } from "./Schemas";

const Table = () => {
  const [search, setSearch] = React.useState("");
  const [selectedItems, setSelectedItems] = React.useState<React.Key[]>([]);
  const [data, setData] = React.useState<TravelReceive[]>([]);
  const [selectedItemId, setSelectedItemId] = React.useState<React.Key>();
  const [limit, setLimit] = React.useState<ILimit>(10);

  const query = useQuery<Travel[]>("travels-list", () => getTravels(), {
    refetchOnMount: true,
  });
  const [showModal, setShowModal] = React.useState(false);
  const navigate = useNavigate();
  const { mutate } = useMutation({
    mutationFn: (id: React.Key | undefined) => {
      return remove(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("travels-list");
      setShowModal(false);
      toast.success("Viagem Deletada");
    },
  });
  useEffect(() => {}, [query.isFetched]);

  const handleSetFormatData = () => {
    const queryDataFormated = query?.data?.map((item) => {
      const players = item.users_travels?.length;
      const startDateFormat = formatedDateToShow(item.startdate);
      const endDateFormat = formatedDateToShow(item.enddate);
      const statusReceive = handleSetStatus(item.startdate, item.enddate);

      const formatData: TravelReceive = {
        ...item,
        users: players,
        startdate: startDateFormat,
        enddate: endDateFormat,
        status: statusReceive,
      };
      return { ...formatData };
    });
    const tableData = queryDataFormated ? queryDataFormated : [];
    setData(tableData);
  };
  React.useEffect(() => {
    handleSetFormatData();
  }, [query.data]);

  useEffect(() => {
    setSelectedId(NEW_ITEM);
  }, []);

  const queryData: Travel[] = query.data ? query.data : [];
  const handleDeleteRows = () => {
    const filteredRows = queryData?.filter((queryData) =>
      selectedItems.includes(queryData.id ? queryData.id : 1)
    );

    if (filteredRows.length > 1) {
      toast.warning("Selecione apenas um item");
    } else {
      mutate(selectedItemId);
    }
  };
  const { setSelectedId } = useSelectedIdContext();

  const handleTravelButtonClicked = () => {
    setSelectedId(selectedItemId);
    navigate("/viagens/viagem");
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const OpenDataButton = () => {
    if (selectedItemId?.valueOf && selectedItems.length === 1) {
      return (
        <Button onClick={handleTravelButtonClicked} color="orange">
          Abrir
        </Button>
      );
    } else {
      return <span></span>;
    }
  };
  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <PerfilInfos>
        <InfoTexts>
          <h1>Lista de Viagens</h1>
        </InfoTexts>
      </PerfilInfos>
      <Header>
        <SearchContainer>
          <SearchSVG />
          <SeachField
            type="text"
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </SearchContainer>
        <HeaderButtonsWrapper>
          <ButtonWrapper>
            <button
              color="green"
              onClick={() => {
                setSelectedId(NEW_ITEM);
                handleNavigate("/viagens/cadastrar");
              }}
            >
              <PlusSVG />
              <span>Novo</span>
            </button>
          </ButtonWrapper>
          <ButtonWrapper buttonColor="#C74C39">
            <OpenDataButton />
          </ButtonWrapper>
          {selectedItemId?.valueOf && selectedItems.length == 1 && (
            <DeleteWrapper
              type="button"
              onClick={() => setShowModal((old) => !old)}
            >
              <DeleteSVG />
              <span>Deletar</span>
            </DeleteWrapper>
          )}
        </HeaderButtonsWrapper>
      </Header>
      <TableContainer>
        <DataGrid
          loading={query.isLoading}
          components={{
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nada para ver aqui
              </Stack>
            ),
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nada para ver aqui
              </Stack>
            ),
          }}
          onCellDoubleClick={(params, event) => {
            if (!event.ctrlKey) {
              setSelectedId(params.row.id);
              handleNavigate("/viagens/viagem");
              event.defaultMuiPrevented = true;
            }
          }}
          style={{
            paddingTop: 5,
            paddingLeft: 5,
            borderRadius: 10,
            border: "none",
            height: "100%",
            width: "100%",
            outline: "none",
            fontFamily: "Poppins",
            color: "#646464",
          }}
          rows={
            search
              ? data.filter((item: TravelReceive) =>
                  item.destination?.toLowerCase().includes(search.toLowerCase())
                )
              : data
          }
          checkboxSelection
          pageSize={limit}
          rowsPerPageOptions={[5]}
          columns={columns}
          onSelectionModelChange={(newSelection) => {
            setSelectedItemId(newSelection[0]);
            setSelectedItems(newSelection.map((item) => item));
          }}
        />
        <ConfirmModal
          handleCancel={handleCancel}
          handleConfirm={handleDeleteRows}
          open={showModal}
          message="Deseja mesmo deletar essa viagem"
        />
      </TableContainer>
      <PageSizeSelector>
        <label htmlFor="page">Visualizar</label>
        <select
          name="page"
          id="page"
          onChange={(e) => setLimit(Number(e.target.value) as ILimit)}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
        </select>
      </PageSizeSelector>
    </Container>
  );
};
export default Table;
